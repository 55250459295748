const ajaxTargetElements = document.querySelectorAll('[data-ajax-target]');

if (ajaxTargetElements) {
    ajaxTargetElements.forEach(el => {
        const target = el.getAttribute('data-ajax-target');
        if (target) {
            // SELECT
            if (el.tagName === 'SELECT') {
                el.addEventListener('change', () => {
                    const xhr = new XMLHttpRequest();
                    const formData = new FormData();

                    formData.append(el.getAttribute('name'), el.value)
                    
                    xhr.open('POST', target, true);
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            el.nextElementSibling.innerHTML = xhr.response;
                        }
                    };
                    xhr.send(formData);
                });
            }
        }
    });
}